import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppIncidentConfigComponent } from './incident-config/incident-config.component';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IncidentConfigOptionCategoryMenuItemComponent } from './incident-config/incident-config-option-category-menu-item/incident-config-option-category-menu-item.component';
import { AppOptionCreateEditModalComponent } from './incident-config/option-create-edit-modal/option-create-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IncidentCreateEditComponent } from './incident-create-edit/incident-create-edit.component';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppStudentProfilePhotoModule } from '../../shared/student-profile-photo/student-profile-photo.module';
import { AddParticipantModalComponent } from './incident-create-edit/add-participant-modal/add-participant-modal.component';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppIncidentListComponent } from './incident-list/incident-list.component';
import { IncidentListFiltersComponent } from './incident-list/incident-list-filters/incident-list-filters.component';
import { IncidentsTableComponent } from './incident-list/incidents-table/incidents-table.component';
import { RouterLink } from '@angular/router';
import { AppInputsModule } from '../../shared/inputs/inputs.module';
import { ReferralEditComponent } from './referral-edit/referral-edit.component';
import { DispositionEditComponent } from './referral-edit/disposition-edit/disposition-edit.component';

@NgModule({
  declarations: [
    AppIncidentConfigComponent,
    IncidentConfigOptionCategoryMenuItemComponent,
    AppOptionCreateEditModalComponent,
    IncidentCreateEditComponent,
    AddParticipantModalComponent,
    AppIncidentListComponent,
    IncidentListFiltersComponent,
    IncidentsTableComponent,
    ReferralEditComponent,
    DispositionEditComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    AppStudentProfilePhotoModule,
    AppPaginationModule,
    DirectiveModule,
    FormsModule,
    RouterLink,
    AppInputsModule
  ]
})
export class AppIncidentModule {}
