import {
  BehaviorEntryDailyActivityReportResponseDto,
  DateUtil,
  IBehaviorEntryDailyActivityReportFilterDto,
  IDisplayData,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../../shared/tables/common-table/base-common-table.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppReportsService } from '../../reports.service';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

export enum DailyActivityReportTableColumns {
  STUDENT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  GRADE = 'Grade',
  ENTRY_DATE = 'Entry Date',
  ENTRY_TIME = 'Entry Time',
  BEHAVIOR_CODE = 'Behavior Code',
  REACTION = 'Reaction',
  ISSUED_BY = 'Issued By'
}

interface IActionsColumnContext {
  row: BehaviorEntryDailyActivityReportResponseDto;
}

@Component({
  selector: 'app-daily-activity-report-table',
  templateUrl: './daily-activity-report-table.template.html',
  styleUrls: ['./daily-activity-report-table.scss']
})
export class AppDailyActivityReportTable extends BaseCommonTableComponent<
  BehaviorEntryDailyActivityReportResponseDto,
  DailyActivityReportTableColumns
> {
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public filters: IBehaviorEntryDailyActivityReportFilterDto;
  public schoolId: number | null;
  public behaviorCodes: IDisplayData[];
  public columns;

  public userOptions: IDisplayData[];
  public behaviorCodeOptions: IDisplayData[];
  public startDate: Date;
  public endDate: Date;
  private destroy$ = new Subject<void>();

  public constructor(
    public reportsService: AppReportsService,
    public pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    private readonly toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super();
    this.templates = {};
    this.columns = DailyActivityReportTableColumns;
  }

  public ngOnInit() {
    this.loadData();

    // subscribe to the PageHeaderService to listen for the EXPORT event
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `Daily Activity Report Export`,
            content:
              'Are you sure you want to export the daily activity report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportsService.exportBehaviorEntryDailyActivityReport(
                this.filters
              );
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.isLoading = true;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.behaviorEntries.results;
    this.userOptions = data.userOptions;
    this.behaviorCodes = data.behaviorCodeOptions;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT
    );
    this.tableFilters.count = data.behaviorEntries.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.startDate = data.defaultFilters.startDate;
    this.endDate = data.defaultFilters.endDate;
    this.filters = {
      ...this.tableFilters,
      startDate: this.startDate,
      endDate: this.endDate,
      schoolId: this.schoolId
    };

    this.isLoading = false;
  }

  public async onFiltersUpdated(
    filters: IBehaviorEntryDailyActivityReportFilterDto
  ) {
    this.filters = filters;
    await this.applyFilters();
  }

  public async applyFilters() {
    this.startDate = this.filters.startDate;
    this.isLoading = true;
    const newData = await this.reportsService
      .getBehaviorEntryDailyActivityReport(this.filters)
      .then(
        (
          res: PaginationResultsDto<BehaviorEntryDailyActivityReportResponseDto>
        ) => res
      );
    this.tableFilters.count = newData.options.totalItems;
    this.dataArr = newData.results;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = {
      ...this.filters,
      ...newTableFilters,
      schoolId: this.schoolId,
      startDate: this.startDate,
      endDate: this.endDate
    };
    await this.applyFilters();
    this.isLoading = false;
  }

  public getColumnValue(
    column: string,
    row: BehaviorEntryDailyActivityReportResponseDto
  ): any {
    const entryDateVal =
      typeof row.entryDate === 'number'
        ? row.entryDate
        : parseInt(row.entryDate);
    switch (column) {
      case DailyActivityReportTableColumns.STUDENT_ID:
        return row.studentId;
      case DailyActivityReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case DailyActivityReportTableColumns.GRADE:
        return row.grade;
      case DailyActivityReportTableColumns.ISSUED_BY:
        return row.issuedBy;
      case DailyActivityReportTableColumns.ENTRY_DATE:
        const val =
          typeof row.entryDate === 'number'
            ? row.entryDate
            : parseInt(row.entryDate);
        return this.convertTimeStampToDate(entryDateVal);
      case DailyActivityReportTableColumns.ENTRY_TIME:
        return this.convertTimeStampToTime(entryDateVal);
      case DailyActivityReportTableColumns.REACTION:
        return row.reaction;
      case DailyActivityReportTableColumns.BEHAVIOR_CODE:
        return row.behaviorCode;
      default:
        return '';
    }
  }

  public convertTimeStampToDate(timestamp: number): string {
    return DateUtil.convertTimestampToDisplayDate(timestamp);
  }

  public convertTimeStampToTime(timestamp: number): string {
    return DateUtil.convertTimestampToDisplayTime(timestamp);
  }
}
