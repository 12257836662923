<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="twoColumnLayout">
  <div class="column">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title> INCIDENT INFORMATION </mat-card-title>
      </mat-card-header>

      <mat-card-content class="mat-elevation-z3">
        <div class="form-row">
          <form [formGroup]="dateTimeForm" class="date-time-form">
            <div class="half-width-input">
              <mat-form-field class="full-width-input">
                <mat-label>Date</mat-label>
                <input
                  formControlName="date"
                  matInput
                  [matDatepicker]="datePicker"
                  type="text" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <app-error-display
                  [form]="dateTimeForm"
                  controlName="date"></app-error-display>
              </mat-form-field>
            </div>
            <div class="half-width-input">
              <mat-form-field class="full-width-input">
                <mat-label>Time</mat-label>
                <input formControlName="time" matInput type="time" />
                <app-error-display
                  [form]="dateTimeForm"
                  controlName="time"></app-error-display>
              </mat-form-field>
            </div>
          </form>
        </div>
        <form [formGroup]="incidentForm">
          <mat-form-field class="full-width-input">
            <mat-label>Incident Type</mat-label>
            <mat-select formControlName="incidentTypeId">
              <mat-option
                *ngFor="let incidentType of incidentTypeOptions"
                [value]="incidentType.value">
                {{ incidentType.display }}
              </mat-option>
            </mat-select>
            <app-error-display
              [form]="incidentForm"
              controlName="incidentTypeId"></app-error-display>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-label>Incident Place</mat-label>
            <mat-select formControlName="incidentPlaceId">
              <mat-option
                *ngFor="let incidentPlace of incidentPlaceOptions"
                [value]="incidentPlace.value">
                {{ incidentPlace.display }}
              </mat-option>
            </mat-select>
            <app-error-display
              [form]="incidentForm"
              controlName="incidentPlaceId"></app-error-display>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-label> Description</mat-label>
            <textarea
              formControlName="description"
              matInput
              rows="4"></textarea>
            <app-error-display
              [form]="incidentForm"
              controlName="description"></app-error-display>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title> PEOPLE INVOLVED </mat-card-title>
      </mat-card-header>
    </mat-card>
    <mat-card-content class="mat-elevation-z3">
      <div class="add-student-button-container">
        <button
          mat-raised-button
          color="primary"
          (click)="openAddParticipantModal()">
          + STUDENTS
        </button>
      </div>
      <ul class="participant-list">
        <li
          class="participant-list-item"
          *ngFor="let participant of participants">
          <span class="participant-icon">
            <app-student-profile-photo
              [selectedImage]="participant.profilePhotoUrl"
              [studentId]="participant.id"
              [hideProfileImageSelector]="true"
              [imageWidth]="30"
              [imageHeight]="30">
            </app-student-profile-photo>
            <div class="participant-name-id-container">
              <span class="participant-name">
                {{ participant.firstName }} {{ participant.lastName }}
              </span>
              <span class="participant-id">
                ID: {{ participant.studentExtId }}</span
              >
            </div>
          </span>

          <div [class]="'role-' + participant.type">
            {{ participant.type }}
          </div>

          <div *ngIf="hasReferral(participant.id)" class="referral-added-pill">
            REFERRAL ADDED
          </div>

          <button
            *ngIf="!hasReferral(participant.id)"
            mat-flat-button
            [disabled]="!participant.incidentParticipantId"
            class="action-button"
            (click)="createReferral(participant.incidentParticipantId)">
            ADD REFERRAL
          </button>

          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="removeParticipant(participant.id)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </li>
      </ul>
    </mat-card-content>
  </div>
</div>
<div class="referrals-container">
  <div class="referral-title">Referrals</div>
  <div class="mat-table">
    <mat-table class="referrals-list-table" [dataSource]="referrals">
      <ng-container matColumnDef="{{ referralColumns.STUDENT }}">
        <mat-header-cell *matHeaderCellDef>
          {{ referralColumns.STUDENT }}
        </mat-header-cell>
        <mat-cell *matCellDef="let referral">
          {{ referral.studentName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ referralColumns.ROLE }}">
        <mat-header-cell *matHeaderCellDef>
          {{ referralColumns.ROLE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let referral">
          {{ referral?.role }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ referralColumns.REFERRAL_STATUS }}">
        <mat-header-cell *matHeaderCellDef>
          {{ referralColumns.REFERRAL_STATUS }}
        </mat-header-cell>
        <mat-cell *matCellDef="let referral"> {{ referral.status }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ referralColumns.ACTION }}">
        <mat-header-cell *matHeaderCellDef>
          {{ referralColumns.ACTION }}
        </mat-header-cell>
        <mat-cell *matCellDef="let referral">
          <button [routerLink]="'/referral/' + referral.id" mat-flat-button class="action-button">REVIEW</button>

          <button
            *ngIf="canDeleteReferral()"
            mat-flat-button
            (click)="deleteReferral(referral.id)"
            class="action-button icon-button">
            <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="referralDisplayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let referral; columns: referralDisplayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
