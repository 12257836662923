<form [formGroup]="form">
    <mat-form-field class="full-width-input">
      <mat-label>Disposition</mat-label>
      <mat-select formControlName="dispositionId">
        <mat-option *ngFor="let option of dispositionOptions" [value]="option.value">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width-input">
      <mat-label>Disposition Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  <mat-form-field class="full-width-input">
    <mat-label>Duration</mat-label>
    <mat-select formControlName="durationId">
      <mat-option *ngFor="let option of durationOptions" [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button
    type="submit"
    (click)="deleteDisposition();"
    mat-flat-button
    class="action-button icon-button"
    >
    <fa-icon [icon]="['fas', 'trash-alt']" />
  </button>
</form>
