import { Injectable } from '@angular/core';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';
import {
  AccruedReportFiltersDto,
  AccruedReportResponseDto,
  BehaviorEntryDailyActivityReportResponseDto,
  CommunicationsReportFiltersDto,
  CommunicationsReportRefreshResponseDto,
  CommunicationsReportResponseDto,
  IBehaviorEntryDailyActivityReportFilterDto,
  IDisplayData,
  PaginationResultsDto,
  SchoolActivityReportFiltersDto,
  SchoolActivityReportResponseDto,
  SchoolWideReportFiltersDto,
  SchoolwideReportResponseDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { IDailyActivityReportResolverData } from './daily-activity-report/daily-activity-report.resolver';
import { IComplianceListFilters } from '../compliance/compliance-list/compliance-list-models/compliance-list-filters.interface';
import dayjs from 'dayjs';

export interface ICommunicationsReportResolverData {
  report: PaginationResultsDto<CommunicationsReportResponseDto>;
  behaviorCodeOptions: IDisplayData[];
}

@Injectable({
  providedIn: 'root'
})
export class AppReportsService extends BaseService {
  public constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getBehaviorEntryDailyActivityReportResolverData(): Promise<IDailyActivityReportResolverData> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;

    const defaultFilters =
      this.getDefaultBehaviorEntryDailyActivityReportFilters(schoolId);
    if (!schoolId) {
      return {
        defaultFilters,
        behaviorEntries: {
          results: [],
          options: {
            page: defaultFilters.page,
            itemsPerPage: defaultFilters.itemsPerPage,
            totalItems: 0
          }
        } as any,
        userOptions: [],
        behaviorCodeOptions: []
      };
    }
    return {
      defaultFilters,
      behaviorEntries:
        await this.getBehaviorEntryDailyActivityReport(defaultFilters),
      userOptions: await this.getUserOptions(defaultFilters.schoolId),
      behaviorCodeOptions: await this.getBehaviorCodeOptions(
        defaultFilters.schoolId
      )
    };
  }

  public async exportBehaviorEntryDailyActivityReport(
    filters: IBehaviorEntryDailyActivityReportFilterDto
  ): Promise<void> {
    return this.appClientDataService.execute(
      this.GET_ROUTES.EXPORT_BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT,
      {
        queryParams: {
          ...(filters.startDate
            ? { startDate: filters.startDate.getTime() }
            : {}),
          ...(filters.endDate ? { endDate: filters.endDate.getTime() } : {}),
          ...(filters.userId ? { userId: filters.userId } : {}),
          ...(filters.behaviorCodeId
            ? { behaviorCodeId: filters.behaviorCodeId }
            : {}),
          schoolId: filters.schoolId
        }
      }
    );
  }

  public getDefaultBehaviorEntryDailyActivityReportFilters = (
    schoolId: number
  ) => {
    return {
      schoolId,
      ...TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT
      ),
      // make sure to use the beginning of the day
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().endOf('day').toDate()
    };
  };

  public getCommunicationsReportDefaultFilters(
    schoolId: number
  ): CommunicationsReportFiltersDto {
    const today = new Date();
    const options = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.COMMUNICATIONS_REPORT
    );
    return new CommunicationsReportFiltersDto({
      schoolId,
      itemsPerPage: options.itemsPerPage,
      page: 1,
      startDate: new Date(today.getFullYear(), today.getMonth(), 1)
    });
  }

  public async getCommunicationsReport(
    filters: CommunicationsReportFiltersDto
  ): Promise<PaginationResultsDto<CommunicationsReportResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<CommunicationsReportResponseDto>
    >(
      this.GET_ROUTES.COMMUNICATIONS_REPORT,
      {
        queryParams: {
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.startDate
            ? { startDate: filters.startDate.getTime() }
            : {}),
          ...(filters.searchString
            ? { searchString: filters.searchString }
            : {}),
          ...(filters.endDate ? { endDate: filters.endDate.getTime() } : {}),
          ...(filters.status ? { status: filters.status } : {}),
          ...(filters.behaviorCodeId
            ? { behaviorCodeId: filters.behaviorCodeId }
            : {}),
          ...(filters.itemsPerPage
            ? { itemsPerPage: filters.itemsPerPage }
            : {}),
          ...(filters.page ? { page: filters.page } : {})
        }
      },
      PaginationResultsDto<CommunicationsReportResponseDto>
    );
  }

  public async refreshCommunicationsReport(
    schoolId: number,
    entryIds: number[]
  ): Promise<CommunicationsReportRefreshResponseDto[]> {
    return await this.appClientDataService.execute<
      CommunicationsReportRefreshResponseDto[]
    >(
      this.GET_ROUTES.REFRESH_BEHAVIOR_CODE_NOTIFICATION_ENTRIES,
      {
        pathParams: {
          schoolId
        },
        queryParams: {
          entryIds
        }
      },
      CommunicationsReportRefreshResponseDto
    );
  }

  public async getNotificationEntryContent(
    entry: CommunicationsReportResponseDto
  ): Promise<[string, string]> {
    const currentUser = this.StorageManager.getLoggedInUser();
    return await this.appClientDataService.execute<[string, string]>(
      this.GET_ROUTES.BEHAVIOR_CODE_NOTIFICATION_ENTRY_CONTENT,
      {
        queryParams: {
          schoolId: currentUser.currentSchoolId,
          entryId: entry.id
        }
      }
    );
  }

  public async getCommunicationsReportResolverData(): Promise<ICommunicationsReportResolverData> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const filters = this.getCommunicationsReportDefaultFilters(schoolId);
    const report = await this.getCommunicationsReport(filters);
    const behaviorCodeOptions =
      await this.getBehaviorCodeNotificationOptions(schoolId);
    return {
      report,
      behaviorCodeOptions
    };
  }

  public async getBehaviorEntryDailyActivityReport(
    filters: IBehaviorEntryDailyActivityReportFilterDto
  ): Promise<
    PaginationResultsDto<BehaviorEntryDailyActivityReportResponseDto>
  > {
    return this.appClientDataService.execute<
      PaginationResultsDto<BehaviorEntryDailyActivityReportResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT, {
      queryParams: {
        ...(filters.startDate
          ? { startDate: filters.startDate.getTime() }
          : {}),
        ...(filters.endDate ? { endDate: filters.endDate.getTime() } : {}),
        ...(filters.userId ? { userId: filters.userId } : {}),
        ...(filters.behaviorCodeId
          ? { behaviorCodeId: filters.behaviorCodeId }
          : {}),
        schoolId: filters.schoolId,
        ...(filters.itemsPerPage ? { itemsPerPage: filters.itemsPerPage } : {}),
        ...(filters.page ? { page: filters.page } : {}),
        ...(filters.showDeactivatedRecords
          ? { showDeactivatedRecords: filters.showDeactivatedRecords }
          : {})
      }
    });
  }

  public async getBehaviorCodeOptions(
    schoolId: number
  ): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_DROPDOWN,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async getBehaviorCodeNotificationOptions(
    schoolId: number
  ): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_WITH_NOTIFICATIONS,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async getUserOptions(schoolId: number): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.USER_DROPDOWN,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public getAccruedReportDefaultFilters(
    schoolId: number
  ): AccruedReportFiltersDto {
    return new AccruedReportFiltersDto({
      ...TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BEHAVIOR_CODE_ACCRUED_REPORT
      ),
      schoolId,
      active: true
    });
  }

  public getSchoolActivityReportDefaultFilters(
    schoolId: number,
    userId?: number
  ): SchoolActivityReportFiltersDto {
    return new SchoolActivityReportFiltersDto({
      ...TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.SCHOOL_ACTIVITY_REPORT
      ),
      schoolId,
      userId,
      active: true,
      noActivityUsers: false
    });
  }

  public getSchoolwideReportDefaultFilters(
    schoolId: number
  ): SchoolWideReportFiltersDto {
    return new SchoolWideReportFiltersDto({
      ...TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.SCHOOLWIDE_POINTS_REPORT
      ),
      schoolId,
      active: true,
      yearToDateTotals: false
    });
  }

  public async getAccruedReport(
    options?: AccruedReportFiltersDto,
    forExport?: boolean
  ): Promise<PaginationResultsDto<AccruedReportResponseDto>> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const filters = options ?? this.getAccruedReportDefaultFilters(schoolId);

    return this.appClientDataService.execute<
      PaginationResultsDto<AccruedReportResponseDto>
    >(
      forExport
        ? this.GET_ROUTES.EXPORT_ACCRUED_REPORT
        : this.GET_ROUTES.BEHAVIOR_CODES_ACCRUED_REPORT,
      {
        queryParams: {
          active: filters.active,
          ...(filters.behaviorCodeId
            ? { behaviorCodeId: filters.behaviorCodeId }
            : {}),
          ...(filters.text ? { text: filters.text } : {}),
          ...(filters.grade ? { grade: filters.grade } : {}),
          ...(filters.startDate ? { startDate: filters.startDate } : {}),
          ...(filters.endDate ? { endDate: filters.endDate } : {}),
          ...(filters.minCount ? { minCount: filters.minCount } : {}),
          ...(filters.maxCount ? { maxCount: filters.maxCount } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.itemsPerPage
            ? { itemsPerPage: filters.itemsPerPage }
            : {}),
          ...(filters.page ? { page: filters.page } : {})
        }
      }
    );
  }

  public async getSchoolActivityReport(
    options?: SchoolActivityReportFiltersDto,
    forExport?: boolean
  ): Promise<PaginationResultsDto<SchoolActivityReportResponseDto>> {
    const currentUser = this.StorageManager.getLoggedInUser();
    const schoolId = currentUser.currentSchoolId;
    // Filter results by user if user is a teacher
    const userId = this.isUserType([UserTypeEnum.TEACHER])
      ? currentUser.id
      : null;
    const filters =
      options ?? this.getSchoolActivityReportDefaultFilters(schoolId, userId);

    return this.appClientDataService.execute<
      PaginationResultsDto<SchoolActivityReportResponseDto>
    >(
      forExport
        ? this.GET_ROUTES.EXPORT_BEHAVIOR_CODE_ENTRY_SCHOOL_ACTIVITY_REPORT
        : this.GET_ROUTES.BEHAVIOR_CODE_ENTRY_SCHOOL_ACTIVITY_REPORT,
      {
        queryParams: {
          active: filters.active,
          noActivityUsers: false,
          ...(filters.text ? { text: filters.text } : {}),
          ...(filters.startDate ? { startDate: filters.startDate } : {}),
          ...(filters.endDate ? { endDate: filters.endDate } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.userId ? { userId: filters.userId } : {}),
          ...(filters.active ? { active: filters.active } : {}),
          ...(filters.noActivityUsers
            ? { noActivityUsers: filters.noActivityUsers }
            : {}),
          ...(filters.itemsPerPage
            ? { itemsPerPage: filters.itemsPerPage }
            : {}),
          ...(filters.page ? { page: filters.page } : {})
        }
      }
    );
  }

  public async getSchoolwidePointsReport(
    options?: SchoolWideReportFiltersDto,
    forExport?: boolean
  ): Promise<PaginationResultsDto<SchoolwideReportResponseDto>> {
    const currentUser = this.StorageManager.getLoggedInUser();
    const schoolId = currentUser.currentSchoolId;
    const filters = options ?? this.getSchoolwideReportDefaultFilters(schoolId);

    return this.appClientDataService.execute<PaginationResultsDto<any>>(
      forExport
        ? this.GET_ROUTES.EXPORT_SCHOOLWIDE_POINTS_REPORT
        : this.GET_ROUTES.SCHOOLWIDE_POINTS_REPORT,
      {
        queryParams: {
          active: filters.active,
          yearToDateTotals: filters.yearToDateTotals,
          ...(filters.startDate ? { startDate: filters.startDate } : {}),
          ...(filters.endDate ? { endDate: filters.endDate } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.grade ? { grade: filters.grade } : {}),
          ...(filters.text ? { text: filters.text } : {}),
          ...(filters.itemsPerPage
            ? { itemsPerPage: filters.itemsPerPage }
            : {}),
          ...(filters.page ? { page: filters.page } : {})
        }
      }
    );
  }

  public async exportComplianceReport(filters: IComplianceListFilters) {
    return this.appClientDataService.execute(
      this.GET_ROUTES.EXPORT_COMPLIANCE_REPORT,
      {
        queryParams: filters
      }
    );
  }
}
