import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppIncidentService } from '../incident.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IIncidentCreateEditResolverData } from '../incident-create-edit/incident-create-edit-resolver-data.interface';
import { IReferralEditResolverData } from './referral-edit-resolver-data.interface';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';

@Injectable({providedIn: 'root'})
export class AppReferralEditResolver extends BaseResolver<IReferralEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public incidentService: AppIncidentService,
    public router: Router,
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IReferralEditResolverData > {
    const referralId = route.params.id;
    return this.incidentService.getEditReferralResolverData(referralId);
  }
}
