<div class="school-activity-report-filters-container">

  <mat-form-field class="filter-item">
    <mat-label>Start Date</mat-label>
    <input
      placeholder="Start Date"
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      (dateChange)="onDateUpdated('startDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label>End Date</mat-label>
    <input
      placeholder="End Date"
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (dateChange)="onDateUpdated('endDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label>
      Grade Level
    </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('grade', $event)"
      placeholder="Grade Level">
      <mat-option
        *ngFor="let option of studentsGrades"
        [value]="option">
          {{option}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button
    type="button"
    mat-raised-button
    (click)="onApplyFilters()"
    class="secondary-button"
    [disabled]="loadingItems"
    [attr.data-test-id]="'apply-filters-schoolwide-btn'">
    Apply Filters
  </button>
</div>
