import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppSchoolGroupsService } from './school-groups.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { BaseComponent } from '../../shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  SchoolGroupCreateDto,
  SchoolGroupResponseDto,
  SchoolGroupSettings,
  SchoolGroupUpdateDto,
  TimezoneDict
} from '@whetstoneeducation/hero-common';
import { plainToInstance } from 'class-transformer';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../shared/validation/validation.util';
import { Subscription } from 'rxjs';
import { AppPageHeaderService } from '../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { validate } from 'class-validator';

@Component({
  selector: 'app-school-group-create-edit',
  templateUrl: './school-group-create-edit.template.html'
})
export class AppSchoolGroupCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public schoolGroup: SchoolGroupCreateDto | SchoolGroupUpdateDto;
  public schoolGroupForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public isUpdate: boolean;
  public timezoneOptions: { value: string; label: string }[] = [];
  constructor(
    formBuilder: FormBuilder,
    private toastManager: AppToastManagerService,
    route: ActivatedRoute,
    router: Router,
    private pageHeaderService: AppPageHeaderService,
    private schoolGroupsService: AppSchoolGroupsService
  ) {
    super({ route, router });
    this.setupSaveButton();
    this.loadData(this.route.snapshot.data.data);
    this.schoolGroupForm = dtoToFormGroup(this.schoolGroup, formBuilder);
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.schoolGroupForm.valueChanges.subscribe(async () => {
        if (this.isUpdate)
          this.schoolGroup = await validateAndGetValue<SchoolGroupUpdateDto>(
            this.schoolGroupForm,
            SchoolGroupUpdateDto
          );
        else
          this.schoolGroup = await validateAndGetValue<SchoolGroupCreateDto>(
            this.schoolGroupForm,
            SchoolGroupCreateDto
          );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  loadData(data: SchoolGroupResponseDto) {
    try {
      this.timezoneOptions = Object.values(TimezoneDict);
      if (!data.id) {
        this.isUpdate = false;
        this.schoolGroup = plainToInstance(SchoolGroupCreateDto, data);
        this.schoolGroup.settings = new SchoolGroupSettings({});
      } else {
        this.isUpdate = true;
        this.schoolGroup = plainToInstance(SchoolGroupUpdateDto, data);
        this.schoolGroup.mapTransforms(this.schoolGroup);
      }
    } catch (e) {
      this.toastManager.error('Error loading school group.');
    }
  }

  setupSaveButton() {
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe(async (action) => {
        if (
          action === HeaderButtonAction.SAVE &&
          formCanSave(this.schoolGroupForm) &&
          formCanSave(this.schoolGroupForm.get('settings') as FormGroup)
        ) {
          await this.saveSchoolGroup();
        }
      })
    );
  }

  public async saveSchoolGroup() {
    try {
      const settingsErrors = (await validate(this.schoolGroup.settings)).map(
        (e) => e.property
      );

      if (settingsErrors.length > 0) {
        const settingsForm = this.schoolGroupForm.get('settings') as FormGroup;
        settingsErrors.forEach((e) => {
          const control = settingsForm.get(e);
          control.markAsTouched();
          control.setErrors({ requiredMessage: 'This field is required' });
        });
        return;
      }

      let result: SchoolGroupResponseDto;
      if (this.schoolGroup instanceof SchoolGroupCreateDto) {
        result = await this.schoolGroupsService.createSchoolGroup(
          this.schoolGroup
        );
      } else {
        result = await this.schoolGroupsService.updateSchoolGroup(
          this.schoolGroup
        );
      }
      this.toastManager.success(`School group "${result.name}" saved!`);
      this.router.navigate(['/school-groups']);
    } catch (e) {
      if (e.status === 409) {
        this.toastManager.error('Error: School Group name already used');
      } else {
        this.toastManager.error('Error saving school group!');
      }
    }
  }
}
