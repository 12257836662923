"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportSettingsCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const enums_1 = require("../../enums");
const boolean_transform_1 = require("../../constants/boolean.transform");
const export_field_mapping_dto_1 = require("./export-field-mapping.dto");
const exportRange_decorator_1 = require("../shared/exportRange.decorator");
class ExportSettingsCreateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'schoolGroupId',
            'fileName',
            'fileDelimiter',
            'contactEmails',
            'includeHeader',
            'startDate',
            'exportInterval',
            'sftpAccountId',
            'exportTime',
            'exportEndTime',
            'exportDays',
            'filters',
            'mappingConfig',
            'schoolIds',
            'behaviorCodeIds',
            'exportCodeTimeFilter',
            'isSchoolGroupLevel',
        ];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
    isSchoolGroupLevel;
    schoolGroupId;
    schoolIds;
    behaviorCodeIds;
    fileName;
    fileDelimiter;
    contactEmails;
    includeHeader;
    startDate;
    exportInterval;
    exportCodeTimeFilter;
    exportCron;
    exportDays;
    exportTime;
    exportEndTime;
    sftpAccountId;
    mappingConfig;
    filters;
}
exports.ExportSettingsCreateDto = ExportSettingsCreateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)({ message: 'Group or school level must be selected' }),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ExportSettingsCreateDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)({ message: 'Selected school group is invalid' }),
    __metadata("design:type", Number)
], ExportSettingsCreateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)({}, { each: true }),
    (0, class_validator_1.ArrayMinSize)(1, { message: 'You must select at least one school option.' }),
    __metadata("design:type", Array)
], ExportSettingsCreateDto.prototype, "schoolIds", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)({}, { each: true }),
    (0, class_validator_1.ArrayMinSize)(1, {
        message: 'You must select at least one behavior code option.'
    }),
    __metadata("design:type", Array)
], ExportSettingsCreateDto.prototype, "behaviorCodeIds", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'You must add a name for the export file.' }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "fileName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'You must add select a delimiter.' }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "fileDelimiter", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'Please provide at least one contact email.' }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "contactEmails", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ExportSettingsCreateDto.prototype, "includeHeader", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsDate)({ message: 'Please provide a valid start date.' }),
    (0, class_transformer_1.Transform)(({ value }) => new Date(value)),
    __metadata("design:type", Date)
], ExportSettingsCreateDto.prototype, "startDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsEnum)(enums_1.ExportFrequency, { message: 'You must select a sync frequency.' }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "exportInterval", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsEnum)(enums_1.ExportCodeTimes, { message: 'You must select a record option.' }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "exportCodeTimeFilter", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "exportCron", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.ExportDays)('exportInterval'),
    __metadata("design:type", Object)
], ExportSettingsCreateDto.prototype, "exportDays", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.ExportTime)('exportTime', 'exportInterval', {
        message: 'Invalid time. Must be HH:MM AM/PM format. Time cannot be between 10PM and 12AM CST.'
    }),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "exportTime", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.ExportTime)('exportEndTime', 'exportInterval', {
        message: 'Invalid time. Must be HH:MM AM/PM format. Time cannot be between 10PM and 12AM CST.'
    }),
    (0, exportRange_decorator_1.ExportTimeRange)('exportTime', 'startDate', 'exportInterval'),
    __metadata("design:type", String)
], ExportSettingsCreateDto.prototype, "exportEndTime", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)({}, {
        message: 'No SFTP Account was found for the selected school or group. Please make sure the school or group has an SFTP account configured.'
    }),
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)({}, {
        message: 'No SFTP Account was found for the selected school or group. Please make sure the school or group has an SFTP account configured.'
    }),
    __metadata("design:type", Number)
], ExportSettingsCreateDto.prototype, "sftpAccountId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.ValidateNested)({ message: 'Valid mapping configuration required.' }),
    __metadata("design:type", export_field_mapping_dto_1.ExportFieldMapping)
], ExportSettingsCreateDto.prototype, "mappingConfig", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], ExportSettingsCreateDto.prototype, "filters", void 0);
