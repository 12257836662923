import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  DateUtil,
  DefaultOptionCategoriesIncidentEnum,
  DispositionCreateDto,
  DispositionUpdateDto,
  IDisplayData,
  IncidentResponseDto,
  IncidentStatusEnum,
  IncidentUpdateDto,
  PrivilegeEnum,
  ReferralDetailResponseDto,
  ReferralReviewUpdateDto,
  ReferralUpdateDto,
  StudentDetailResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppIncidentService } from '../incident.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IReferralEditResolverData } from './referral-edit-resolver-data.interface';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { AppPrivilegesService } from '../../auth/privileges.service';

@Component({
  selector: 'app-referral-edit',
  templateUrl: './referral-edit.component.html',
  styleUrls: ['./referral-edit.component.scss']
})
export class ReferralEditComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public referralId: number;
  public referral: ReferralDetailResponseDto;
  public student: StudentDetailResponseDto;
  public incident: IncidentResponseDto;
  public referralForm: FormGroup;
  public referralReviewForm: FormGroup;
  public localDispositionForms: {
    [key: number]: FormGroup;
  };
  public stateDispositionForms: {
    [key: number]: FormGroup;
  };

  public isReviewing: boolean;
  public studentWithSpecialNeedsOptions: IDisplayData[] = [];
  public bullyingOptions: IDisplayData[] = [];
  public motivationOptions: IDisplayData[] = [];
  public othersInvolvedOptions: IDisplayData[] = [];
  public basisOfBullyingOptions: IDisplayData[] = [];
  public previousActionTakenOptions: IDisplayData[] = [];
  public incidentContextOptions: IDisplayData[] = [];
  public severityLevelOptions: IDisplayData[] = [];
  public dueProcessOptions: IDisplayData[] = [];
  public localDispositionOptions: IDisplayData[] = [];
  public stateDispositionOptions: IDisplayData[] = [];
  public durationOptions: IDisplayData[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    public incidentService: AppIncidentService,
    public route: ActivatedRoute,
    public router: Router,
    public pageHeaderService: AppPageHeaderService,
    public toastService: AppToastManagerService,
    public formBuilder: FormBuilder,
    public privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
  }

  ngOnInit() {
    this.loadData(this.route.snapshot.data.data);
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: HeaderButtonAction) => {
        switch (action) {
          case HeaderButtonAction.SAVE:
            await this.saveChanges();
            break;
          case HeaderButtonAction.SAVE_AND_SUBMIT:
            await this.saveChanges(true);
            break;
          case HeaderButtonAction.REJECT:
            await this.changeStatus(IncidentStatusEnum.REJECTED);
            await this.router.navigate(['/incident/', this.incident.id]);
            break;
          case HeaderButtonAction.APPROVE:
            await this.changeStatus(IncidentStatusEnum.FINALIZED);
            await this.router.navigate(['/incident/', this.incident.id]);
            break;
          case HeaderButtonAction.BACK:
            await this.router.navigate(['/incident/', this.incident.id]);
            break;
          case HeaderButtonAction.PRINT:
            // window.print();
            this.toastService.info('Printing is not available at this time');
            break;
          default:
            break;
        }
      });
    if (this.isReviewing) {
      this.pageHeaderService.changeButtons([
        {
          name: 'Back',
          action: HeaderButtonAction.BACK,
          style: 'cancel-button'
        },
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'success-button'
        },
        {
          name: 'APPROVE',
          action: HeaderButtonAction.APPROVE,
          style: 'success-button'
        },
        {
          name: 'REJECT',
          action: HeaderButtonAction.REJECT,
          style: 'reject-button',
          privilege: PrivilegeEnum.MANAGE_INCIDENTS
        }
      ]);
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(data: IReferralEditResolverData) {
    this.isLoading = true;
    this.isReviewing =
      [
        IncidentStatusEnum.PENDING,
        IncidentStatusEnum.FINALIZED,
        IncidentStatusEnum.REJECTED
      ].includes(data.referral.status as IncidentStatusEnum) &&
      this.hasPrivilege(PrivilegeEnum.FINALIZE_INCIDENTS);

    this.basisOfBullyingOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING
      ];
    this.bullyingOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.BULLYING];
    this.motivationOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.MOTIVATION];
    this.othersInvolvedOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED];
    this.previousActionTakenOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN
      ];
    this.studentWithSpecialNeedsOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS
      ];
    this.incidentContextOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.INCIDENT_CONTEXT
      ];
    this.severityLevelOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.SEVERITY_LEVEL];
    this.dueProcessOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.DUE_PROCESS];
    this.localDispositionOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION
      ];
    this.stateDispositionOptions =
      data.referralOptions[
        DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION
      ];
    this.durationOptions =
      data.referralOptions[DefaultOptionCategoriesIncidentEnum.DURATION];

    this.loadReferral(data.referral);

    this.isLoading = false;
  }

  public loadReferral(referral: ReferralDetailResponseDto) {
    this.referralId = referral.id;
    this.referral = referral;
    this.student = referral.student;
    this.incident = referral.incident;
    const referralUpdateDto = new ReferralUpdateDto();
    referralUpdateDto.mapFields(referral);

    this.referralForm = dtoToFormGroup(referralUpdateDto, this.formBuilder, {
      mapId: false,
      disable: this.isReviewing
        ? [
            'studentWithSpecialNeedsId',
            'bullyingId',
            'incidentContextId',
            'motivationId',
            'basisOfBullyingId',
            'previousActionTakenId',
            'othersInvolvedId',
            'notes'
          ]
        : []
    });
    if (this.isReviewing) {
      const referralReviewUpdateDto = new ReferralReviewUpdateDto();
      referralReviewUpdateDto.mapFields(referral);
      this.referralReviewForm = dtoToFormGroup(
        referralReviewUpdateDto,
        this.formBuilder,
        {
          mapId: false
        }
      );

      this.stateDispositionForms = {};

      for (const disposition of referral.stateDispositions) {
        const dto = new DispositionUpdateDto();
        dto.mapFields(disposition);
        this.stateDispositionForms[disposition.id] = dtoToFormGroup(
          dto,
          this.formBuilder,
          {
            mapId: false
          }
        );
      }

      this.localDispositionForms = {};
      for (const disposition of referral.localDispositions) {
        const dto = new DispositionUpdateDto();
        dto.mapFields(disposition);
        this.localDispositionForms[disposition.id] = dtoToFormGroup(
          dto,
          this.formBuilder,
          {
            mapId: false
          }
        );
      }
    }
  }

  public async changeStatus(status: IncidentStatusEnum) {
    this.isLoading = true;
    try {
      await this.incidentService.updateReferral(
        this.referralId,
        new ReferralUpdateDto({ status })
      );
      this.toastService.success(
        `Referral ${
          status === IncidentStatusEnum.FINALIZED ? 'finalized' : 'rejected'
        }`
      );
      await this.router.navigate(['/incident/', this.incident.id]);
    } catch (e) {
      this.toastService.error('Error rejecting referral');
    } finally {
      this.isLoading = false;
    }
  }

  public async saveChanges(submit?: boolean) {
    this.isLoading = true;
    if (
      !this.isReviewing &&
      formCanSave(this.referralForm, this.toastService, false)
    ) {
      const dto = await validateAndGetValue<ReferralUpdateDto>(
        this.referralForm,
        ReferralUpdateDto
      );
      try {
        if (submit) {
          dto.status = IncidentStatusEnum.PENDING;
        }

        await this.incidentService.updateReferral(this.referralId, dto);
        this.toastService.success('Referral updated successfully');
      } catch (e) {
        this.toastService.error('Error updating referral');
      } finally {
        this.isLoading = false;
      }
    } else if (this.isReviewing) {
      this.isLoading = true;
      if (formCanSave(this.referralReviewForm, this.toastService, false)) {
        const dto = await validateAndGetValue<ReferralReviewUpdateDto>(
          this.referralReviewForm,
          ReferralReviewUpdateDto
        );
        try {
          if (submit) {
            dto.status = IncidentStatusEnum.PENDING;
          }

          await this.incidentService.updateReferralReview(this.referralId, dto);
          this.toastService.success('Referral updated successfully');
        } catch (e) {
          this.toastService.error('Error updating referral');
        }
      }
      const dispositionsToUpdate = [];

      for (const key of Object.keys(this.stateDispositionForms)) {
        const form = this.stateDispositionForms[key] as FormGroup;
        if (
          form.touched &&
          formCanSave(this.stateDispositionForms[key], this.toastService)
        ) {
          const dto = await validateAndGetValue<DispositionUpdateDto>(
            form,
            DispositionUpdateDto
          );
          dto.id = parseInt(key);
          dispositionsToUpdate.push(dto);
        }
      }

      for (const key of Object.keys(this.localDispositionForms)) {
        const form = this.localDispositionForms[key] as FormGroup;
        if (
          form.touched &&
          formCanSave(this.localDispositionForms[key], this.toastService)
        ) {
          const dto = await validateAndGetValue<DispositionUpdateDto>(
            form,
            DispositionUpdateDto
          );
          dto.id = parseInt(key);
          dispositionsToUpdate.push(dto);
        }
      }

      if (dispositionsToUpdate.length > 0) {
        try {
          await this.incidentService.updateDispositions(
            this.referralId,
            dispositionsToUpdate
          );
          this.toastService.success('Dispositions updated successfully');
        } catch (e) {
          this.toastService.error('Error updating dispositions');
        }
      }

      Object.values(this.stateDispositionForms).forEach((form) => {
        form.markAsUntouched();
      });

      Object.values(this.localDispositionForms).forEach((form) => {
        form.markAsUntouched();
      });

      this.isLoading = false;
    }
  }

  public async addDisposition(type: 'local' | 'state') {
    let dispositionsTouched = false;

    Object.values(this.localDispositionForms).forEach((form) => {
      if (form.touched) {
        dispositionsTouched = true;
      }
    });

    Object.values(this.stateDispositionForms).forEach((form) => {
      if (form.touched) {
        dispositionsTouched = true;
      }
    });

    if (dispositionsTouched) {
      this.toastService.error(
        'Please save changes to dispositions before adding a new disposition'
      );
      return;
    }

    const dto = new DispositionCreateDto({
      type,
      referralId: this.referralId
    });
    this.isLoading = true;
    try {
      await this.incidentService.createDisposition(dto);
      const referral = await this.incidentService.getReferral(this.referralId);
      this.loadReferral(referral);
    } catch (e) {
      this.toastService.error('Error adding disposition');
    }
    this.isLoading = false;
  }

  public async deleteDisposition(id: string) {
    this.isLoading = true;
    console.log('id', id);
    try {
      await this.incidentService.deleteDisposition(this.referralId, +id);
      const referral = await this.incidentService.getReferral(this.referralId);
      this.loadReferral(referral);
      this.toastService.success('Disposition deleted successfully');
    } catch (e) {
      this.toastService.error('Error deleting disposition');
    }
    this.isLoading = false;
  }

  protected readonly DateUtil = DateUtil;
  protected readonly Object = Object;
  protected readonly parseInt = parseInt;
}
