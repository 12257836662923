"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispositionResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class DispositionResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'type',
            'disposition',
            'startDate',
            'duration',
            'dispositionId',
            'durationId'
        ];
    }
    id;
    type;
    disposition;
    dispositionId;
    startDate;
    duration;
    durationId;
}
exports.DispositionResponseDto = DispositionResponseDto;
