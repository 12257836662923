<div class="page-content">
  <app-students-list-filters
    [filters]="filters"
    (filtersUpdated)="updateFilters($event)"
    [selectedStudentIds]="selectedStudentsIds"
    (showFastTrackModalEvent)="showFastTrackModal()"
    (showTrackModalEvent)="showTrackModal()">
  </app-students-list-filters>
  <div class="custom-roster-list" *ngIf="!isLoading">
    <button
      *ngFor="let roster of customRosterOptions"
      (click)="handleSelectRoster(roster.id)"
      [className]="
        customRoster.id === roster.id ? 'selected-roster-tab' : 'roster-tab'
      ">
      <div class="custom-roster-item__name">{{ roster.name }}</div>
    </button>
    <button class="create-roster-tab" 
      (click)="showCreateCustomRosterModal()"
      [attr.data-test-id]="'add-roster-btn'">
      <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> &nbsp; Add Roster
    </button>
  </div>
  <div class="manage-roster-row">
    <div class="left-section">
      <button
        *ngIf="customRoster"
        class="manage-roster-button"
        (click)="handleSelectAll()">
        {{ selectedStudentsIds.length > 1 ? 'DE-SELECT ALL' : 'SELECT ALL' }}
      </button>

      <app-menu title="Sort By" [actions]="sortActionButtons"></app-menu>

      <button
        *ngIf="sortField"
        class="manage-roster-button"
        (click)="onSortDirectionClicked()">
        {{ sortDirection.toLocaleUpperCase() }}
      </button>

      <app-menu [actions]="bulkActionButtons"></app-menu>
    </div>
    <div class="right-section">
      <button
        *ngIf="customRoster"
        class="manage-roster-button"
        (click)="router.navigate(['/custom-roster/', customRoster.id])">
        MANAGE ROSTER
      </button>
    </div>
  </div>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <div class="table-container mat-elevation-z2">
    <div *ngIf="customRoster" class="card-container">
      <app-student-card
        *ngFor="let student of customRoster.students"
        [student]="student"
        [studentSelected]="selectedStudentsIds.includes(student.id)"
        [selectStudent]="selectStudent">
      </app-student-card>
    </div>
  </div>
</div>
