"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
class ReferralUpdateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'studentWithSpecialNeedsId',
            'bullyingId',
            'motivationId',
            'basisOfBullyingId',
            'previousActionTakenId',
            'incidentContextId',
            'othersInvolvedId',
            'severityLevelId',
            'dueProcessId',
            'status',
            'notes'
        ];
    }
    studentWithSpecialNeedsId;
    bullyingId;
    motivationId;
    basisOfBullyingId;
    previousActionTakenId;
    incidentContextId;
    othersInvolvedId;
    severityLevelId;
    dueProcessId;
    status;
    notes;
}
exports.ReferralUpdateDto = ReferralUpdateDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "studentWithSpecialNeedsId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "bullyingId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "motivationId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "basisOfBullyingId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "previousActionTakenId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "incidentContextId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "othersInvolvedId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "severityLevelId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ReferralUpdateDto.prototype, "dueProcessId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ReferralUpdateDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], ReferralUpdateDto.prototype, "notes", void 0);
