import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { NotificationTemplateResponseDto } from '@whetstoneeducation/hero-common';
import { NotificationTemplateListColumns } from '../template-list-models/notification-template-list-columns.enum';
import { Router } from '@angular/router';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppNotificationService } from '../../notification.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-notification-template-list-table',
  templateUrl: './notification-template-list-table.template.html',
  styleUrls: ['./notification-template-list-table.scss']
})
export class AppNotificationTemplateListTableComponent extends BaseTableComponent<NotificationTemplateResponseDto> {
  public get displayedColumns() {
    return Object.values(NotificationTemplateListColumns);
  }

  public columnValues = {
    [NotificationTemplateListColumns.NAME]: ['name'],
    [NotificationTemplateListColumns.IS_GROUP_LEVEL]: ['isSchoolGroupLevel'],
    [NotificationTemplateListColumns.SCHOOL_GROUP]: ['schoolGroup_name'],
    [NotificationTemplateListColumns.SCHOOL]: ['school_name'],
    [NotificationTemplateListColumns.BODY_TYPE]: ['bodyType']
  };

  public columns = NotificationTemplateListColumns;

  constructor(
    readonly router: Router,
    public confirmationDialog: AppConfirmationDialogService,
    public notificationService: AppNotificationService,
    private toastManager: AppToastManagerService
  ) {
    super();
  }

  public editTemplate(template: NotificationTemplateResponseDto): void {
    this.router.navigate(['notification-templates', template.id]);
  }

  public deleteTemplate(template: NotificationTemplateResponseDto): void {
    const templateId = template.id;
    const ref = this.confirmationDialog.openDialog({
      title: `Remove Notification Template`,
      content: 'Are you sure you want to delete the notification template?'
    });
    ref.afterClosed().subscribe(async (result: string) => {
      if (result === DEFAULT_DIALOG_RESULT.YES) {
        await this.notificationService.removeNotificationTemplate(templateId);
        this.toastManager.success(
          'Notification Template deleted successfully!'
        );
        // remove notification template from data
        this.dataArr = this.dataArr.filter(
          (notification) => notification.id !== templateId
        );
      }
    });
  }
}
