"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralsOptionsResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
// There is an error exporting enums from common, remove this once we fix it
var DefaultOptionCategoriesIncidentEnum;
(function (DefaultOptionCategoriesIncidentEnum) {
    DefaultOptionCategoriesIncidentEnum["INCIDENT_TYPES"] = "incident_types";
    DefaultOptionCategoriesIncidentEnum["INCIDENT_PLACES"] = "incident_places";
    DefaultOptionCategoriesIncidentEnum["INCIDENT_ROLES_AND_REFERRALS"] = "incident_roles_and_referrals";
    DefaultOptionCategoriesIncidentEnum["STUDENT_WITH_SPECIAL_NEEDS"] = "student_with_special_needs";
    DefaultOptionCategoriesIncidentEnum["MOTIVATION"] = "motivation";
    DefaultOptionCategoriesIncidentEnum["OTHERS_INVOLVED"] = "others_involved";
    DefaultOptionCategoriesIncidentEnum["BULLYING"] = "bullying";
    DefaultOptionCategoriesIncidentEnum["BASIS_OF_BULLYING"] = "basis_of_bullying";
    DefaultOptionCategoriesIncidentEnum["PREVIOUS_ACTION_TAKEN"] = "previous_action_taken";
    DefaultOptionCategoriesIncidentEnum["HERO_DISPOSITION"] = "hero_disposition";
    DefaultOptionCategoriesIncidentEnum["LOCAL_DISPOSITION"] = "local_disposition";
    DefaultOptionCategoriesIncidentEnum["STATE_DISPOSITION"] = "state_disposition";
    DefaultOptionCategoriesIncidentEnum["INCIDENT_CONTEXT"] = "incident_context";
    DefaultOptionCategoriesIncidentEnum["SEVERITY_LEVEL"] = "severity_level";
    DefaultOptionCategoriesIncidentEnum["DUE_PROCESS"] = "due_process";
    DefaultOptionCategoriesIncidentEnum["DURATION"] = "duration";
})(DefaultOptionCategoriesIncidentEnum || (DefaultOptionCategoriesIncidentEnum = {}));
class ReferralsOptionsResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'bullying',
            'motivation',
            'basisOfBullying',
            'previousActionTaken',
            'incidentContext',
            'severityLevel',
            'dueProcess'
        ];
    }
    [DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS];
    [DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING];
    [DefaultOptionCategoriesIncidentEnum.MOTIVATION];
    [DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN];
    [DefaultOptionCategoriesIncidentEnum.BULLYING];
    [DefaultOptionCategoriesIncidentEnum.INCIDENT_CONTEXT];
    [DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED];
    [DefaultOptionCategoriesIncidentEnum.SEVERITY_LEVEL];
    [DefaultOptionCategoriesIncidentEnum.DUE_PROCESS];
    [DefaultOptionCategoriesIncidentEnum.DURATION];
    [DefaultOptionCategoriesIncidentEnum.HERO_DISPOSITION];
    [DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION];
    [DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION];
}
exports.ReferralsOptionsResponseDto = ReferralsOptionsResponseDto;
