<div class="communications-report-filters-container">
  <mat-form-field class="filter-item">
    <input
      #searchInput
      matInput
      type="text"
      placeholder="Search Students"
      (change)="onFiltersChanged()"
      (input)="onSearchUpdated($event)" />
  </mat-form-field>

  <!-- add status filter -->
  <mat-form-field class="filter-item">
    <mat-select
      #statusSelect
      placeholder="Status"
      (selectionChange)="onStatusUpdated($event)">
      <mat-option *ngFor="let status of statuses" [value]="status">
        {{ status }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-select
      #behaviorCodeSelect
      placeholder="Behavior Code"
      (selectionChange)="onBehaviorCodeUpdated($event)">
      <mat-option
        *ngFor="let behaviorCode of behaviorCodes"
        [value]="behaviorCode.value">
        {{ behaviorCode.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <input
      #startDateInput
      placeholder="Start Date"
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      (blur)="onDateTouched('startDate')"
      (dateChange)="onDateUpdated('startDate', $event.target.value)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker
      #startDatePicker
      (closed)="onDateTouched('startDate')"></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <input
      #endDateInput
      placeholder="End Date"
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (blur)="onDateTouched('endDate')"
      (dateChange)="onDateUpdated('endDate',$event.target.value)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker
      #endDatePicker
      (closed)="onDateTouched('endDate')"></mat-datepicker>
  </mat-form-field>

  <button
    *ngIf="filtersChanged"
    type="button"
    mat-raised-button
    (click)="onApplyFilters()"
    class="apply-filter-button secondary-button"
    [disabled]="loadingItems"
    [attr.data-test-id]="'apply-filters-communications-btn'">
    Apply Filters
  </button>
  <button
    *ngIf="!filtersCleared"
    type="button"
    mat-raised-button
    (click)="onClearFilters()"
    class="clear-filter-button secondary-button"
    [disabled]="loadingItems">
    Clear Filters
  </button>
</div>
