<form *ngIf="userForm" [formGroup]="userForm" novalidate>
  <div class="twoColumnLayout">
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>School Access</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-elevation-z3">
          <!-- User School Group -->
          <mat-form-field class="full-width-input">
            <mat-label>School Group</mat-label>
            <mat-select formControlName="schoolGroupId">
              <mat-option
                *ngFor="let schoolGroup of schoolGroups"
                [value]="schoolGroup.value"
                options-id="schoolGroups"
                [option]="constantize(schoolGroup.display)">
                {{schoolGroup.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="schools" class="full-width-input">
            <mat-label>School(s)</mat-label>
            <mat-select multiple formControlName="schools">
              <mat-option
                *ngFor="let school of schools"
                [value]="school.value"
                [option]="constantize(school.display)"
                options-id="schools">
                {{school.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- user type selector -->
          <mat-form-field class="full-width-input">
            <mat-label>User Type</mat-label>
            <mat-select formControlName="type" unique-id>
              <mat-option
                *ngFor="let userType of userTypes; let i = index"
                [value]="userType.value"
                options-id="userTypes"
                [option]="constantize(userType.display)">
                {{userType.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-warning-banner-component
            *ngIf="showRoles() && selectedSchools?.length > 1"
            bannerClass="custom-banner-container">
            <span class="banner-text"
              >Select a role for each selected school (Optional)</span
            >
          </app-warning-banner-component>
          <ng-container *ngIf="showRoles()">
            <mat-form-field
              class="full-width-input"
              *ngFor="let school of selectedSchools">
              <mat-label>Roles at {{ school.display }}</mat-label>
              <mat-select
                multiple
                [formControlName]="'school' + school.value + 'Roles'"
                [attr.options-id]="'school ' + school.value + ' roles'">
                <mat-option
                  *ngFor="let role of getSchoolRoles(school.value)"
                  [value]="role.value"
                  options-id="roles"
                  [option]="constantize(role.display)">
                  {{role.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <mat-form-field
            class="full-width-input"
            *ngIf="teacherUserTypeSelected">
            <mat-label>Instructor Code</mat-label>
            <input formControlName="instructorCode" matInput />
            <app-error-display
              [form]="userForm"
              controlName="instructorCode"></app-error-display>
          </mat-form-field>
          <button
            *ngIf="userId"
            mat-flat-button
            (click)="setPassword()"
            class="secondary-button standard-button"
            options-id="SET_PASSWORD"
            [option]="userId">
            <fa-icon [icon]="['fas','key']"></fa-icon>
            Set Password
          </button>
          <button
            *ngIf="userId && user.type === 'guardian'"
            mat-flat-button
            (click)="activateGuardian()"
            class="activate-button outline-button standard-button"
            options-id="ACTIVATE_GUARDIAN"
            [option]="userId">
            <fa-icon [icon]="['fas','lock-keyhole-open']"></fa-icon>
            Activate Guardian
          </button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>User Information</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-elevation-z3">
          <!-- User First Name, user.name with mat-label and app-error-display component -->
          <mat-form-field class="full-width-input">
            <mat-label>First Name<span> *</span></mat-label>
            <input formControlName="firstName" matInput />
            <app-error-display
              [form]="userForm"
              controlName="firstName"></app-error-display>
          </mat-form-field>
          <!-- User Last Name, user.name with mat-label and app-error-display component -->
          <mat-form-field class="full-width-input">
            <mat-label>Last Name<span> *</span></mat-label>
            <input formControlName="lastName" matInput />
            <app-error-display
              [form]="userForm"
              controlName="lastName"></app-error-display>
          </mat-form-field>
          <!-- User Email -->
          <mat-form-field class="full-width-input">
            <mat-label>Email<span> *</span></mat-label>
            <input formControlName="email" matInput placeholder="Email" />
            <app-error-display
              [form]="userForm"
              controlName="email"></app-error-display>
          </mat-form-field>
          <!-- User Address -->
          <mat-form-field class="full-width-input">
            <mat-label>Address</mat-label>
            <input formControlName="address" matInput placeholder="Address" />
            <app-error-display
              [form]="userForm"
              controlName="address"></app-error-display>
          </mat-form-field>
          <!-- User City -->
          <mat-form-field class="full-width-input">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput placeholder="City" />
            <app-error-display
              [form]="userForm"
              controlName="city"></app-error-display>
          </mat-form-field>
          <!-- User State -->
          <mat-form-field class="full-width-input">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" unique-id>
              <mat-option
                *ngFor="let state of stateOptions"
                [value]="state.value"
                options-id="stateOptions"
                [option]="constantize(state.display)">
                {{state.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- User Zip -->
          <mat-form-field class="full-width-input">
            <mat-label>Zip</mat-label>
            <input formControlName="zip" matInput placeholder="Zip" />
            <app-error-display
              [form]="userForm"
              controlName="zip"></app-error-display>
          </mat-form-field>
          <!-- User Phone -->
          <mat-form-field class="full-width-input">
            <mat-label>Phone</mat-label>
            <input formControlName="phone" matInput placeholder="Phone" />
            <app-error-display
              [form]="userForm"
              controlName="phone"></app-error-display>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
