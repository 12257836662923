<!-- Header -->
<div class="mat-dialog-header">
  <!-- Title -->
  <div class="mat-dialog-title">
    <span class="group-name">{{ groupName ? groupName+':' : '' }} </span>
    <span class="title">Add Student(s)</span>
  </div>
</div>

<!-- Content -->
<mat-dialog-content class="mat-dialog-content">
  <span class="help-text"
    >Only students that are not in any group can be added.</span
  >

  <div class="buttons-and-filters">
    <div class="filters">
      <mat-form-field class="filter-item input">
        <input
          [placeholder]="'First Name'"
          data-test-id="FIRST_NAME_FILTER_INPUT"
          matInput
          (keyup.enter)="filters.firstName = $event.target.value; updateFilters()" />
      </mat-form-field>
      <mat-form-field class="filter-item input">
        <input
          [placeholder]="'Last Name'"
          data-test-id="LAST_NAME_FILTER_INPUT"
          matInput
          (keyup.enter)="filters.lastName = $event.target.value; updateFilters()" />
      </mat-form-field>
      <mat-form-field class="filter-item input">
        <input
          [placeholder]="'Grade'"
          data-test-id="GRADE_FILTER_INPUT"
          matInput
          (keyup.enter)="filters.grade = $event.target.value; updateFilters()" />
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      (click)="dialogRef.close(selectedStudents)"
      class="btn secondary-button standard-button"
      [attr.data-test-id]="'add-selected-students-btn'">
      ADD SELECTED STUDENTS
    </button>
  </div>
  <app-student-group-student-list
    [dataArr]="students"
    [tableFilters]="tableFilters"
    [showSelectOptions]="true"
    [selectedStudents]="selectedStudents"
    (tableFiltersUpdated)="updateTableFilters($event)"
    (selectedStudentsChanged)="selectedStudents = $event"
    (studentSelected)="dialogRef.close([$event])">
  </app-student-group-student-list>
</mat-dialog-content>
