import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  ExportFrequency,
  SftpAccountExistingDto,
  SftpAccountResponseDto,
  ExportSettingsCreateDto,
  ExportSettingsResponseDto,
  ExportSettingsUpdateDto,
  ExportFieldMapping,
  ExportField,
  SchoolResponseDto,
  BehaviorCodeResponseDto,
  IDisplayData,
  UserTypeEnum,
  ExportCodeTimes
} from '@whetstoneeducation/hero-common';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { Subject, takeUntil } from 'rxjs';
import {
  dtoToFormGroup,
  getValue
} from '../../../shared/validation/validation.util';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { MatDialog } from '@angular/material/dialog';
import { validate, ValidationError } from 'class-validator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppValidationDialogComponent } from '../../../shared/validation/validation-dialog/validation-dialog.component';
import { CronDaysOfWeek } from '../../../shared/constants/cronDaysOfWeek';
import { AppExportService } from '../export.service';
import { AppSftpService } from '../../imports/sftp.service';
import { exportFieldList } from '../export-models/export-field-list';
import { SftpAccountExistingBase } from '../../imports/sftp-account-existing-base';
import { AppSftpAccountCreateComponent } from '../../imports/sftp-account-create/sftp-account-create.component';
import { AppBehaviorCodesService } from '../../behavior-codes/behavior-codes.service';

export interface Email {
  address: string;
}

@Component({
  styleUrls: ['./export-settings.scss'],
  selector: 'app-export-settings',
  templateUrl: 'export-settings.template.html'
})
export class AppExportSettingsCreateEdit
  extends SftpAccountExistingBase
  implements AfterViewInit, OnDestroy
{
  @Input() delimiterControl: HTMLElement;
  readonly exportFieldList = exportFieldList;
  readonly weekDays = CronDaysOfWeek;
  readonly separatorKeysCodes = [186 /* semicolon keycode */] as const;
  readonly delimiters = [
    { name: 'Comma', value: ',' },
    { name: 'Tab', value: '\t' }
  ] as const;
  public contactEmails: Email[] = [];
  public exportSettings: ExportSettingsCreateDto | ExportSettingsUpdateDto;
  public sftpAccounts: SftpAccountResponseDto[];
  public exportSettingsForm: FormGroup;
  public exportFrequencies = Object.values(ExportFrequency);
  public delimiter = '';
  private selectAllOptions: IDisplayData = { value: -1, display: 'Select All' };
  public includeHeader = false;
  public exportFieldsConfig: ExportFieldMapping;
  public formErrors: ValidationError[] = [];
  private settingsId?: number = null;
  private destroy$: Subject<void> = new Subject<void>();
  public accountFilters: SftpAccountExistingDto;
  public user = this.StorageManager.getLoggedInUser();
  public behaviorCodeOptions: IDisplayData[] = [this.selectAllOptions];
  public schoolsOptions: IDisplayData[] = [];
  private allBehaviorCodePreviouslySelected: boolean = true;
  private allSchoolsPreviouslySelected: boolean = true;
  public isGroupLevelUser: boolean = false;
  public timezone: string;

  public exportCodeTimes = new Map([
    [ExportCodeTimes.DAY, "Today's Records"],
    [ExportCodeTimes.LASTRUN, 'Only New']
  ]);

  constructor(
    public readonly route: ActivatedRoute,
    public readonly formBuilder: FormBuilder,
    public readonly toastService: AppToastManagerService,
    public readonly pageHeaderService: AppPageHeaderService,
    public router: Router,
    private dialog: MatDialog,
    public readonly sftpService: AppSftpService,
    public readonly exportService: AppExportService,
    private readonly behaviorCodesService: AppBehaviorCodesService
  ) {
    super(sftpService);
    this.loadData();
    this.setInitialValues();
    this.setDefaultSchoolsBehaviorCodeOption();
    this.exportSettingsForm = dtoToFormGroup(
      this.exportSettings,
      this.formBuilder,
      {
        updateOn: 'change',
        exclude: ['contactEmails']
      }
    );
  }

  private setDefaultSchoolsBehaviorCodeOption(): void {
    this.exportSettings.behaviorCodeIds = this.exportSettings?.behaviorCodeIds
      ?.length
      ? this.exportSettings.behaviorCodeIds
      : [-1];
    this.exportSettings.schoolIds = this.exportSettings?.schoolIds?.length
      ? this.exportSettings.schoolIds
      : this.isGroupLevelUser && this.schools?.length > 1
      ? [-1]
      : [this.user.currentSchoolId];
  }

  private async setInitialValues(): Promise<void> {
    if (
      this.exportSettings.constructor === ExportSettingsUpdateDto &&
      this.exportSettings.mappingConfig
    ) {
      this.exportFieldsConfig = this.exportSettings.mappingConfig;
      this.exportFieldsConfig.exportFieldList =
        this.exportFieldsConfig.exportFieldList.map(
          (item) => new ExportField(item)
        );
      this.includeHeader = this.exportSettings.includeHeader;
      this.allBehaviorCodePreviouslySelected =
        !this.exportSettings?.behaviorCodeIds?.length;
      this.allSchoolsPreviouslySelected =
        !this.exportSettings?.schoolIds?.length;
    }

    if (this.exportSettings.constructor === ExportSettingsCreateDto) {
      // if creating new export default start date to today
      this.exportSettings.startDate = new Date();
      this.exportSettings.schoolIds = this.isGroupLevelUser
        ? this.schools.length > 1
          ? []
          : [this.schools[0].id]
        : [this.user.currentSchoolId];
      this.exportSettings.schoolGroupId = this.isGroupLevelUser
        ? this.schools.length > 1
          ? this.user.schoolGroupId
          : null
        : null;
      this.exportFieldsConfig = new ExportFieldMapping();
      this.exportSettings.includeHeader = false;
    }
    this.accountFilters = new SftpAccountExistingDto({
      schoolGroupId: this.exportSettings.schoolGroupId,
      schoolId: this.exportSettings.isSchoolGroupLevel
        ? null
        : this.user.currentSchoolId,
      groupLevel: this.exportSettings.isSchoolGroupLevel
    });

    if (this.exportSettings.constructor === ExportSettingsUpdateDto) {
      this.settingsId = this.exportSettings.id;
    }
    await this.updateSftpAccounts();
  }

  get schoolsSelected(): boolean {
    return !!this.exportSettings?.schoolIds?.length;
  }

  private async loadBehaviorCodeOptions(schoolIds?: number[]): Promise<void> {
    const ids = schoolIds ? schoolIds : this.exportSettings?.schoolIds;
    if (!!ids?.length) {
      const actualIds = ids.includes(-1)
        ? this.schools.map((school) => school.id)
        : ids;
      const schoolBehaviorCodes =
        await this.behaviorCodesService.getBehaviorCodesByUserSchools(
          actualIds
        );
      if (schoolBehaviorCodes.length) {
        this.behaviorCodeOptions = [
          ...(schoolBehaviorCodes.length > 1 ? [this.selectAllOptions] : []),
          ...schoolBehaviorCodes
        ];
      } else {
        this.behaviorCodeOptions = [];
        this.toastService.warn(
          'No behavior codes found for selected school(s)'
        );
      }
      const currentBehaviorCodes =
        this.exportSettingsForm.get('behaviorCodeIds').value;
      const keptBehaviorCodes =
        schoolBehaviorCodes.length > 1
          ? this.behaviorCodeOptions
              .filter((item) => currentBehaviorCodes.includes(item.value))
              .map((item) => item.value)
          : [schoolBehaviorCodes[0] ? schoolBehaviorCodes[0].value : -1];
      this.exportSettingsForm
        .get('behaviorCodeIds')
        .setValue(keptBehaviorCodes.length ? keptBehaviorCodes : [-1]);
    }
  }

  private async setValue(): Promise<void> {
    // pull form values into settings dto
    const initialSchoolIds = this.exportSettings.schoolIds;
    if (this.exportSettings.constructor === ExportSettingsUpdateDto) {
      const result = await getValue<ExportSettingsUpdateDto>(
        this.exportSettingsForm,
        ExportSettingsUpdateDto
      );
      this.exportSettings.mapFields(result);
    } else {
      this.exportSettings = await getValue<ExportSettingsCreateDto>(
        this.exportSettingsForm,
        ExportSettingsCreateDto
      );
      this.setContactEmailsFromInput();
    }
    //when school level import the school selector is disabled (get value returns nothing) so we need to manually supply these values
    if (!this.exportSettings.isSchoolGroupLevel) {
      this.exportSettings.schoolIds = initialSchoolIds;
    }
    const schoolsSelected = this.exportSettings?.schoolIds;
    this.exportSettings.schoolGroupId =
      schoolsSelected?.includes(-1) || schoolsSelected.length > 1
        ? this.user.schoolGroupId
        : null;
  }

  private setSchoolIdsChangesSubscription(): void {
    this.exportSettingsForm
      .get('schoolIds')
      .valueChanges.subscribe(async (value: number[]) => {
        if (value?.includes(-1) && !this.allSchoolsPreviouslySelected) {
          this.exportSettingsForm
            .get('schoolIds')
            .setValue([-1], { emitEvent: false });
          this.allSchoolsPreviouslySelected = true;
        } else {
          this.exportSettingsForm.get('schoolIds').setValue(
            value.filter((id) => id !== -1),
            { emitEvent: false }
          );
          this.allSchoolsPreviouslySelected = false;
        }

        const newSchoolIds = this.exportSettingsForm.get('schoolIds').value;
        await this.loadBehaviorCodeOptions(
          newSchoolIds.includes(-1)
            ? this.schools.map((school) => school.id)
            : newSchoolIds
        );
      });
  }

  private setExportSettingsFormChangesSubscription(): void {
    // this isn't being selected properly on load for some reason
    this.exportSettingsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        // save current filter values before updating them
        if (this.exportSettings.sftpAccountId) {
          this.exportSettingsForm
            .get('sftpAccountId')
            .setValue(this.exportSettings.sftpAccountId, { emitEvent: false });
        }

        // parse contact emails to ; separated string
        this.setContactEmailsFromInput();
        await this.setValue();

        // to support the error dialog
        this.setErrors(await validate(this.exportSettings));
      });
  }

  private setBehaviorCodeIdsChangesSubscription(): void {
    this.exportSettingsForm
      .get('behaviorCodeIds')
      .valueChanges.subscribe((value: number[]) => {
        if (value.includes(-1) && !this.allBehaviorCodePreviouslySelected) {
          this.exportSettingsForm
            .get('behaviorCodeIds')
            .setValue([-1], { emitEvent: false });
          this.allBehaviorCodePreviouslySelected = true;
        } else if (value.length > 1) {
          this.exportSettingsForm.get('behaviorCodeIds').setValue(
            value.filter((id) => id !== -1),
            { emitEvent: false }
          );
          this.allBehaviorCodePreviouslySelected = false;
        }
      });
  }

  // if this is a school level import select the user's current school then disable the selector
  private setSingleSchoolExport() {
    this.exportSettings.schoolIds = [this.user.currentSchoolId];
    this.exportSettingsForm
      .get('schoolIds')
      .setValue([this.user.currentSchoolId]);
    this.exportSettingsForm.get('schoolIds').disable({ emitEvent: false });
  }

  private setIsSchoolLevelChangesSubscription(): void {
    //force non group level users to school level
    if (!this.isGroupLevelUser) {
      this.exportSettings.isSchoolGroupLevel = false;
      this.accountFilters.groupLevel = false;
      this.exportSettingsForm.get('isSchoolGroupLevel').setValue(false);
      this.setSingleSchoolExport();
      this.updateSftpAccounts();
    } else if (!this.user.currentSchoolId) {
      //if no current school selected must be group level
      this.exportSettings.isSchoolGroupLevel = true;
      this.accountFilters.groupLevel = true;
      this.exportSettingsForm.get('isSchoolGroupLevel').setValue(true);
      this.updateSftpAccounts();
    }

    this.exportSettingsForm
      .get('isSchoolGroupLevel')
      .valueChanges.subscribe((isSchoolGroupLevelUpdatedValue: boolean) => {
        this.exportSettings.isSchoolGroupLevel = isSchoolGroupLevelUpdatedValue;
        if (!this.exportSettings.isSchoolGroupLevel) {
          this.setSingleSchoolExport();
        } else {
          this.exportSettingsForm.get('schoolIds').enable({ emitEvent: false });
        }

        this.accountFilters = new SftpAccountExistingDto({
          schoolGroupId: this.exportSettings.schoolGroupId,
          schoolId: this.exportSettings.isSchoolGroupLevel
            ? null
            : this.user.currentSchoolId,
          groupLevel: this.exportSettings.isSchoolGroupLevel
        });
        this.updateSftpAccounts();
      });
  }

  private setFormChangesSubscriptions(): void {
    this.setExportSettingsFormChangesSubscription();
    this.setSchoolIdsChangesSubscription();
    this.setBehaviorCodeIdsChangesSubscription();
    this.setIsSchoolLevelChangesSubscription();
  }

  public async ngAfterViewInit(): Promise<void> {
    this.setFormChangesSubscriptions();
    this.loadBehaviorCodeOptions();
  }

  private setErrors(errors: ValidationError[], filterErrors?: string[]): void {
    if (filterErrors) {
      errors = errors.filter((error) => !filterErrors.includes(error.property));
    }
    this.formErrors = errors;
  }

  public async accountClicked(event: MouseEvent): Promise<void> {
    const accountId = this.sftpAccounts[0].accountId;
    if (accountId) {
      await navigator.clipboard.writeText(accountId);
      this.toastService.success('Account ID copied to clipboard');
    }

    event.stopPropagation();
  }

  private async updateSftpAccounts(): Promise<void> {
    //if they haven't selected a group level or school level we don't know which ftp account to pull
    if (this.accountFilters.groupLevel === undefined) {
      return;
    }
    this.sftpAccounts = await this.sftpService.getMatchingSftpAccounts(
      this.accountFilters
    );
    this.exportSettings.sftpAccountId = this.sftpAccounts[0]?.id;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createNewAccount(): void {
    this.dialog
      .open(AppSftpAccountCreateComponent, {
        width: '500px',
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        await this.updateSftpAccounts();
      });
  }

  public loadData(): void {
    this.isGroupLevelUser = this.isUserType([
      UserTypeEnum.GROUP_ADMIN,
      UserTypeEnum.INTERNAL_ADMIN
    ]);
    super.loadData();
    this.isLoading = true;
    const data = this.route.snapshot.data.data as ExportSettingsResponseDto;

    if (data.id) {
      this.exportSettings = new ExportSettingsUpdateDto(data);
    } else {
      this.exportSettings = new ExportSettingsCreateDto(data);
    }
    this.currentUser = this.StorageManager.getLoggedInUser();
    this.schoolGroups = this.currentUser.schoolGroups;
    this.schools = this.isGroupLevelUser
      ? this.currentUser.schoolGroups[0].schools
      : this.currentUser.schoolGroups[0].schools.filter(
          (s) => s.id === this.currentUser.currentSchoolId
        );
    if (this.isGroupLevelUser && this.schools?.length > 1) {
      this.schoolsOptions.push(this.selectAllOptions);
    }
    this.schoolsOptions = [
      ...this.schoolsOptions,
      ...this.schools.map((s) => ({ value: s.id, display: s.name }))
    ];
    const user = this.StorageManager.getLoggedInUser();
    this.timezone = user.settings.timezone;
    this.populateContactEmailsFromData(data);
    this.isLoading = false;
  }

  // Convert semicolon-delimited string to array of email addresses
  private populateContactEmailsFromData(data: ExportSettingsResponseDto): void {
    this.contactEmails = data.contactEmails
      ? data.contactEmails
          .split(';')
          .map((email) => ({ address: email.trim() }))
      : [];
  }

  private setContactEmailsFromInput(): void {
    this.exportSettings.contactEmails = this.contactEmails
      .map((email) => email.address)
      .join('; ');
    validate(this.exportSettings).then((errors) => {
      this.setErrors(errors, ['exportFieldsConfig']);
    });
  }

  public addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our email
    if (value) {
      this.contactEmails.push({ address: value });
    }

    this.setContactEmailsFromInput();
    // Clear the input value
    event.chipInput!.clear();
  }

  public removeEmail(email: Email): void {
    const index = this.contactEmails.indexOf(email);

    if (index >= 0) {
      this.contactEmails.splice(index, 1);
    }

    this.setContactEmailsFromInput();
  }

  public editEmail(email: Email, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove email if it no longer has an address
    if (!value) {
      this.removeEmail(email);
      return;
    }

    // Edit existing email
    const index = this.contactEmails.indexOf(email);
    if (index >= 0) {
      this.contactEmails[index].address = value;
    }

    this.setContactEmailsFromInput();
  }

  private showMappingDialog(): void {
    this.dialog.open(AppValidationDialogComponent, {
      disableClose: true,
      data: {
        errors: this.formErrors,
        formControlMapping: {
          sftpAccountId: 'SFTP Account',
          startDate: 'Start Date',
          contactEmails: 'Contact Emails',
          mappingConfig: 'Mapping Configuration',
          fileName: 'File Name Test',
          fileDelimiter: 'Delimiter',
          includeHeader: 'Header',
          exportInterval: 'Interval',
          exportTime: 'Time',
          exportEndTime: 'End Time',
          exportDays: 'Export Days',
          behaviorCodeIds: 'Behavior Code Options',
          schoolIds: 'Schools',
          exportCodeTimeFilter: 'Record Options',
          isSchoolGroupLevel: 'School Group Level'
        }
      }
    });
    this.dialog.afterAllClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setErrors([]);
    });
  }

  public updateExportFields(data: ExportFieldMapping) {
    this.exportFieldsConfig = data;
  }

  public async runExport(): Promise<void> {
    await this.saveSettings();
    if (this.formErrors.length > 0) return;
    await this.exportService.runExport(this.settingsId);
  }

  public async saveSettings(): Promise<void> {
    if (
      this.exportSettings &&
      !this.exportSettings.sftpAccountId &&
      this.sftpAccounts &&
      this.sftpAccounts.length > 0
    ) {
      this.exportSettings.sftpAccountId = this.sftpAccounts[0].id;
    }

    this.exportSettings.mappingConfig = this.exportFieldsConfig;

    this.setErrors(await validate(this.exportSettings));

    if (this.formErrors.length > 0) {
      this.showMappingDialog();
      this.toastService.error('Please correct the errors on the form');
      return;
    }

    // clear out fields not needed
    if (!this.showTime() && !this.showStartEndTime()) {
      this.exportSettings.exportTime = null;
    }

    if (!this.showStartEndTime()) {
      this.exportSettings.exportEndTime = null;
    }

    if (!this.showDays()) {
      this.exportSettings.exportDays = [];
    }

    try {
      if (this.exportSettings.constructor === ExportSettingsCreateDto) {
        const result = await this.exportService.createExportSettings(
          this.exportSettings
        );
        this.settingsId = result.id;
        this.exportSettings = new ExportSettingsUpdateDto().mapFields(result);
        this.setDefaultSchoolsBehaviorCodeOption();
        this.exportSettingsForm = dtoToFormGroup(
          this.exportSettings,
          this.formBuilder,
          {
            updateOn: 'change',
            exclude: ['contactEmails']
          }
        );
        this.setFormChangesSubscriptions();
        this.router.navigate(['export/settings', this.settingsId]);
      } else {
        const result = await this.exportService.updateExportSettings(
          this.exportSettings as ExportSettingsUpdateDto
        );
      }

      this.toastService.success('Export settings saved successfully');
    } catch (err) {
      this.toastService.error('Error saving Export settings');
    }
  }

  public headerToggle(event: MatCheckboxChange): void {
    this.exportSettings.includeHeader = event.checked;
  }

  public showTime(): boolean {
    const show = [
      ExportFrequency.Daily,
      ExportFrequency.Weekly,
      ExportFrequency.Monthly
    ];
    return show.includes(this.exportSettings.exportInterval);
  }

  public showStartEndTime(): boolean {
    const show = [ExportFrequency.Hourly];
    return show.includes(this.exportSettings.exportInterval);
  }

  public showDays(): boolean {
    const show = [ExportFrequency.Weekly, ExportFrequency.Hourly];
    return show.includes(this.exportSettings.exportInterval);
  }

  protected readonly ExportFrequency = ExportFrequency;
}
